import { withConfiguration } from "./config";
export const fetcher = (root, verb) => (url, payload) => {
  return withConfiguration(config => {
    return fetch(`${config[root]}${url}`, {
      method: verb,
      headers: new Headers({
        "Content-Type": "application/json;charset=UTF-8"
      }),
      body: payload !== undefined ? JSON.stringify(payload) : undefined
    }).then(result => {
      if (result.ok === false) return Promise.reject(new Error(result.statusText));
      return result.json();
    });
  });
};
export const post = fetcher("serverUrl", "GET");
export const getWeather = gps => {
  return post(`/api/${gps}`);
};