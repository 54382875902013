// const POLYFILLS_ID = "msem-polyfills";
// const FEATURES = [
//   "Intl",
//   "Intl.~locale.fr",
//   "Intl.~locale.en",
//   "String.prototype.padStart",
//   "String.prototype.startsWith",
//   "String.prototype.repeat",
//   "String.prototype.padEnd",
//   "String.prototype.endsWith",
//   "Object.keys",
//   "Object.values",
//   "Object.entries",
//   "Object.assign",
//   "Array.from",
//   "Array.prototype.fill",
//   "Array.prototype.find",
//   "Array.prototype.findIndex",
//   "Array.prototype.filter",
//   "Array.prototype.includes",
//   "Element.prototype.matches",
//   "Element.prototype.closest",
//   "requestAnimationFrame",
//   "CustomEvent",
//   "Promise"
// ].join(",");
// export const polyfill = cb => {
//   const el = document.getElementById(POLYFILLS_ID);
//   if (el) return cb();
//   const script = document.createElement("script");
//   script.id = POLYFILLS_ID;
//   script.onload = cb;
//   script.src = `https://cdn.polyfill.io/v3/polyfill.min.js?features=${FEATURES}`;
//   document.head.appendChild(script);
// };
const isProd = () => process.env.NODE_ENV === "production";

const needsCSS = el => el === null && isProd();

const needsJS = el => el === null;

const inject = (tag, props) => {
  const el = document.createElement(tag);
  Object.keys(props).forEach(key => {
    el[key] = props[key];
  });
  document.head.appendChild(el);
};

const injecter = (condition, tag, options) => {
  return new Promise(resolve => {
    return condition(document.getElementById(options.id)) ? inject(tag, { ...options,
      onload: resolve
    }) : resolve();
  });
};

export const loadCSS = ({
  id,
  resourceUrl,
  url
}) => {
  const href = `${resourceUrl}${url}`;
  return injecter(needsCSS, "link", {
    rel: "stylesheet",
    id,
    href
  });
};
export const loadJS = ({
  id,
  url: src
}) => {
  return injecter(needsJS, "script", {
    id,
    src
  });
};
export const getParameterByName = (_name, _url) => {
  const url = _url || window.location.href;

  const name = _name.replace(/[[\]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return undefined;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};