import "core-js/modules/es.object.assign";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Weather from "msem-weather/es/components/Weather";
import { IntlProvider } from "react-intl";
import { withConfiguration } from "msem-weather/es/services/config.js";
import { loadCSS } from "msem-lib/es/services/loader.js";
import fr from "msem-weather/es/locales/fr.json";
import en from "msem-weather/es/locales/en.json";

window.openWeather = ({ language, localisation, containerId, useDefaultCSS }) => {
  const messages = language === "fr" ? fr : en;
  if (useDefaultCSS) {
    const resourceUrl = withConfiguration((config) => config.resourceUrl);
    loadCSS({ id: "msem-weather-css", resourceUrl, url: "/default.css" });
  }
  ReactDOM.render(
    <IntlProvider locale={language} messages={messages}>
      <Weather localisation={localisation} />
    </IntlProvider>,
    document.getElementById(containerId)
  );
};
