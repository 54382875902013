import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

const SvgIcon = props => React.createElement("svg", _extends({
  viewBox: "0 0 24 24"
}, props), props.children);

export const LeftArrowIcon = props => React.createElement(SvgIcon, props, React.createElement("path", {
  d: "M14 7l-5 5 5 5V7z"
}), React.createElement("path", {
  fill: "none",
  d: "M24 0v24H0V0h24z"
}));
export const RightArrowIcon = props => React.createElement(SvgIcon, props, React.createElement("path", {
  d: "M10 17l5-5-5-5v10z"
}), React.createElement("path", {
  fill: "none",
  d: "M0 24V0h24v24H0z"
}));
export const UpArrowIcon = props => React.createElement(SvgIcon, props, React.createElement("path", {
  d: "M7 14l5-5 5 5z"
}), React.createElement("path", {
  d: "M0 0h24v24H0z",
  fill: "none"
}));
export const DownArrowIcon = props => React.createElement(SvgIcon, props, React.createElement("path", {
  d: "M7 10l5 5 5-5z"
}), React.createElement("path", {
  d: "M0 0h24v24H0z",
  fill: "none"
}));