import * as React from "react";
import { PureComponent } from "react";

class WindImage extends PureComponent {
  render() {
    const {
      windSpeed,
      windDirection
    } = this.props;
    const speed = parseInt(windSpeed * 1.6 / 2.5) * 25;
    const direction = parseInt(Math.ceil(windDirection / 10.0) * 10.0);
    const code = ("000" + speed).slice(-4) + "." + ("00" + direction).slice(-3);
    return React.createElement("img", {
      className: "msem-weather-wind-image",
      alt: "",
      src: speed === 0 ? "https://fil.nrk.no/yr/grafikk/vindpiler/32/vindstille.png" : "https://fil.nrk.no/yr/grafikk/vindpiler/32/vindpil." + code + ".png"
    });
  }

}

export default WindImage;