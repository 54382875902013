import _defineProperty from "@babel/runtime/helpers/defineProperty";
import * as React from "react";
import { PureComponent, Fragment } from "react";
import classnames from "classnames";
import { injectIntl } from "react-intl";
import { LeftArrowIcon, RightArrowIcon } from "./Icons";
import WeatherPhone from "./WeatherPhone";
import WindImage from "./WindImage";
import { withConfiguration } from "../services/config";
import { getWeather } from "../services/api";

const renderDate = weathers => {
  return React.createElement("tr", null, React.createElement("td", {
    colSpan: "2",
    className: "msem-weather__date-padding"
  }), weathers.map((weather, index) => React.createElement("td", {
    className: "msem-weather__date",
    key: index
  }, weather.date)));
};

const renderSymbol = (config, symbol, index) => {
  return React.createElement("td", {
    className: "msem-weather__data",
    style: {
      backgroundImage: `url(${config.resourceUrl}/images/sep.png)`
    },
    key: index
  }, React.createElement("img", {
    src: `${config.resourceUrl}/images/weather/${symbol}.svg`,
    alt: "",
    width: "40px",
    height: "40px"
  }));
};

const renderTemperature = (config, weathers) => {
  return React.createElement("tr", null, React.createElement("td", {
    className: "msem-weather__legend",
    style: {
      backgroundImage: `url(${config.resourceUrl}/images/temperature.png)`
    }
  }), weathers.map((weather, index) => React.createElement("td", {
    className: "msem-weather__data",
    style: {
      backgroundImage: `url(${config.resourceUrl}/images/sep.png)`
    },
    key: index
  }, weather.temperature, "\xB0C")));
};

const renderPrecipitation = (config, weathers) => {
  return React.createElement("tr", null, React.createElement("td", {
    className: "msem-weather__legend",
    style: {
      backgroundImage: `url(${config.resourceUrl}/images/snow.png)`
    }
  }), weathers.map((weather, index) => React.createElement("td", {
    className: "msem-weather__data",
    style: {
      backgroundImage: `url(${config.resourceUrl}/images/sep.png)`
    },
    key: index
  }, weather.precipitation, "mm")));
};

const renderWind = (config, weathers) => {
  return React.createElement("tr", null, React.createElement("td", null), React.createElement("td", {
    className: "msem-weather__legend msem-weather__legend-last",
    style: {
      backgroundImage: `url(${config.resourceUrl}/images/wind.png)`
    }
  }), weathers.map((weather, index) => React.createElement("td", {
    key: index,
    className: "msem-weather__data"
  }, React.createElement(WindImage, {
    windDirection: weather.windDirection,
    windSpeed: weather.windSpeed
  }))));
};

class Weather extends PureComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "state", {});

    _defineProperty(this, "clickWeather", () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    });

    _defineProperty(this, "renderWeatherTitle", config => {
      const {
        intl
      } = this.props;
      const {
        weathers,
        isOpen
      } = this.state;
      return React.createElement("tr", null, React.createElement("td", {
        rowSpan: "3",
        className: "msem-weather__toggle",
        onClick: this.clickWeather
      }, React.createElement("div", {
        className: "msem-weather__toggle-icon"
      }, isOpen ? React.createElement(RightArrowIcon, null) : React.createElement(LeftArrowIcon, null)), React.createElement("div", {
        className: "msem-weather__toggle-title"
      }, intl.messages["weather"]), React.createElement("div", {
        className: "msem-weather__toggle-sun",
        style: {
          backgroundImage: `url(${config.resourceUrl}/images/soleil.png)`
        }
      })), React.createElement("td", {
        className: "msem-weather__legend",
        style: {
          backgroundImage: `url(${config.resourceUrl}/images/soleil.png)`
        }
      }), weathers.map((weather, index) => renderSymbol(config, weather.symbol, index)));
    });
  }

  componentDidMount() {
    getWeather(this.props.localisation).then(weathers => this.setState({
      weathers
    })).catch(err => {
      console.log(err.message);
    });
  }

  renderWeather(config) {
    const {
      weathers,
      isOpen
    } = this.state;
    const weatherClasses = classnames("msem-weather", {
      "msem-weather--open": isOpen
    });
    return React.createElement("div", {
      className: "msem-weather__container"
    }, React.createElement("table", {
      className: weatherClasses,
      cellSpacing: "0"
    }, React.createElement("tbody", null, renderDate(weathers), this.renderWeatherTitle(config), renderTemperature(config, weathers), renderPrecipitation(config, weathers), renderWind(config, weathers))));
  }

  render() {
    const {
      weathers,
      isOpen
    } = this.state;
    if (weathers === undefined) return "";
    return React.createElement(Fragment, null, withConfiguration(config => this.renderWeather(config)), React.createElement(WeatherPhone, {
      weathers: weathers,
      isOpen: isOpen
    }));
  }

}

export default injectIntl(Weather);