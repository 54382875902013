import _defineProperty from "@babel/runtime/helpers/defineProperty";
import * as React from "react";
import { PureComponent } from "react";
import classnames from "classnames";
import { injectIntl } from "react-intl";
import { DownArrowIcon, UpArrowIcon } from "./Icons";
import WindImage from "./WindImage";
import { withConfiguration } from "../services/config";

class Weather extends PureComponent {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "state", {});

    _defineProperty(this, "clickWeather", () => {
      this.setState({
        isOpen: !this.state.isOpen
      });
    });
  }

  renderTitle(index) {
    const {
      intl
    } = this.props;
    const {
      isOpen
    } = this.state;
    return index === 0 ? React.createElement("td", {
      className: "msem-weather-phone__title msem-weather-phone__data",
      onClick: this.clickWeather
    }, intl.messages["weather"], React.createElement("div", {
      className: "msem-weather-phone__title-icon"
    }, isOpen ? React.createElement(UpArrowIcon, null) : React.createElement(DownArrowIcon, null))) : React.createElement("td", null);
  }

  renderTable(config) {
    const {
      weathers
    } = this.props;
    return React.createElement("tbody", null, weathers.map((weather, index) => {
      return React.createElement("tr", {
        key: index
      }, this.renderTitle(index), React.createElement("td", {
        className: "msem-weather-phone__data"
      }, weather.date), React.createElement("td", {
        className: "msem-weather-phone__data"
      }, React.createElement("img", {
        src: `${config.resourceUrl}/images/weather/${weather.symbol}.svg`,
        className: "msem-weather-phone__data-symbol",
        alt: ""
      })), React.createElement("td", {
        className: "msem-weather-phone__data"
      }, weather.temperature, "\xB0"), React.createElement("td", {
        className: "msem-weather-phone__data"
      }, weather.precipitation), React.createElement("td", {
        className: "msem-weather-phone__data"
      }, React.createElement(WindImage, {
        windDirection: weather.windDirection,
        windSpeed: weather.windSpeed
      })));
    }));
  }

  render() {
    const {
      isOpen
    } = this.state;
    const weatherClasses = classnames("msem-weather-phone__container", {
      "msem-weather-phone__container--open": isOpen
    });
    return React.createElement("div", {
      className: weatherClasses
    }, React.createElement("table", {
      className: "msem-weather-phone",
      border: "0",
      cellPadding: "0",
      cellSpacing: "0"
    }, withConfiguration(config => this.renderTable(config))));
  }

}

export default injectIntl(Weather);